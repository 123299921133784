.container {
  margin-top: 62px;
  height: 210px;
  align-items: center;
  justify-content: space-between;
}
.container .timeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container .timeContainer .timeTitle {
  font-size: 16px;
  color: #00205B;
  font-weight: 420;
}
.container .timeContainer .timeSubtitle {
  font-size: 10px;
  color: #A5A5BA;
  font-weight: 420;
}
.modal_container {
  margin-top: 0;
  height: 190px;
}
