.container {
  position: relative;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
}
.container .animationIcon {
  width: 56px;
  height: 137px;
  margin-bottom: 37px;
}
.container .animationOpacity {
  opacity: 0.5;
  margin-bottom: 39px;
}
.container .successIcon {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 14%;
  left: 25%;
}
.container .successTitle {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 420;
}
