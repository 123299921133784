.container {
  margin: 0 40px 10px 0;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 10px 14px 25px;
  color: #00205B;
  width: 230px;
  transition: all 0.5s ease;
  border: 2px solid transparent;
  text-align: start;
}
.container:active {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08) !important;
}
.container .warning_icon {
  width: 24px;
  height: 24px;
}
.container.active {
  border: 2px solid #2D78C6;
}
.container.warning {
  border: 2px solid #D02B20;
}
.container.hidden {
  opacity: 0;
  pointer-events: none;
}
.container span {
  display: block;
}
.container .lazy_load_image {
  margin: 8px 0 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 198px;
  height: 137px;
  overflow: hidden;
  border-radius: 8px;
}
.container .lazy_load_image img:not(.warning_icon) {
  background: white;
  pointer-events: none;
  margin: auto 0;
  height: 100%;
  width: auto;
}
.container.withChoice {
  padding-bottom: 12px;
  cursor: auto;
}
