@import "../../variables";

.container {
    margin-top: 62px;
    height: 210px;
    align-items: center;
    justify-content: space-between;

    .timeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .timeTitle {
            font-size: @font-span;
            color: @main-color-cl-01;
            font-weight: 420;
        }

        .timeSubtitle {
            font-size: 10px;
            color: @main-color-cl-15;
            font-weight: 420;
        }
    }
}

.modal_container {
    margin-top: 0;
    height: 190px;
}