.container {
  width: 190px;
  color: #00205B;
  align-items: center;
  margin: 6px 92px 0;
}
.container > span {
  font-size: 18px;
}
.container .header {
  margin-bottom: 29px;
}
.container .status_line {
  width: 100%;
  border: 1px solid #1CE783;
  margin-bottom: 25px;
}
.container .timeContainer {
  align-items: center;
  margin: 18px 0 40px 0;
}
.container .timeContainer > span {
  font-size: 18px;
}
.container .timeContainer .alarmIcon {
  margin-right: 8px;
  width: 17px;
  height: 19px;
}
.modal_container {
  width: 230px;
  background-color: white;
  border-radius: 8px;
  padding: 23px;
}
.modal_container .title {
  font-weight: 500;
  margin: 20px 0 27px;
}
.modal_container > span {
  font-size: 14px;
}
.modal_container .header {
  margin-bottom: 18px;
}
.modal_container .status_line {
  margin-bottom: 16px;
}
.modal_container .timeContainer {
  align-items: center;
  margin: 10px 0 30px 0;
}
.modal_container .timeContainer > span {
  font-size: 14px;
}
.modal_container .historyIcon {
  width: 24px;
  height: 24px;
}
.modal_container .buttonsContainer {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
