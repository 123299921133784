@import "../../variables";

.primaryButton {
    background-color: @main-color-cl-01 !important;
    box-shadow: none !important;
    text-transform: none !important;
    font-size: @font-span !important;
    padding: 0 !important;
    border-radius: 8px !important;

    &:hover {
        background-color: @main-color-cl-01-hover !important;
    }
}

.greyButton {
    background-color: white !important;
    box-shadow: none !important;
    text-transform: none !important;
    font-size: @font-span !important;
    color: @main-color-cl-13 !important;
    font-weight: 600 !important;
    padding: 0 !important;
    border: 1px solid @main-color-cl-13 !important;
    border-radius: 8px !important;

    &:hover {
        background-color: white !important;
    }
}

.iconButton {
    background-color: white !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: 1px solid @main-color-cl-13 !important;
    border-radius: 8px !important;
    min-width: 30px !important;

    &:hover {
        background-color: white !important;
    }
}