.flush_info_container > ul {
  padding: 0;
}
.flush_info_container > ul > li {
  padding: 0;
  justify-content: center;
  text-align: center;
}
.flush_info_container > ul > li > div {
  margin: 8px 0;
}
.flush_info_container > ul > li > div span {
  font-size: 14px !important;
  margin-bottom: 8px;
  color: #666687;
}
.flush_info_container > ul > li > div .MuiTypography-body2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flush_info_container > ul > li > div .MuiTypography-body2 .MuiTypography-body1 {
  font-size: 16px !important;
  color: #00205B;
  font-weight: 600;
  align-self: center;
}
.flush_info_container .MuiDivider-fullWidth {
  margin: 0 4px;
}
.flush_info_container .symbol {
  color: #DCDCE4 !important;
  margin-left: 3px;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  padding-bottom: 2px;
}
.flush_info_container .degreesSymbol {
  padding-bottom: 4px;
}
