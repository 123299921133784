.container {
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.container .temperatureContainer > p {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-right: 13px;
}
.container .temperatureContainer > p::after {
  content: "°C";
  position: absolute;
  top: 4px;
  color: #DCDCE4;
  font-weight: 400;
  font-size: 12px;
}
.container .flushLoading {
  width: 72px !important;
  height: 72px !important;
  margin-bottom: 27px;
  animation: 3s linear 0s normal none infinite running flushLoading;
  -webkit-animation: 3s linear 0s normal none infinite running flushLoading;
}
@keyframes flushLoading {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes flushLoading {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.container .flushingTitle {
  margin-bottom: 8px;
  font-weight: 420;
  font-size: 16px;
}
