@import "../../variables";
@import "../../global";
@import "../../animations";

.usage_map_wrap {
  margin-top: -168px !important;
  max-width: 100vw;
  overflow: hidden;
  height: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 1200px;
  @media (min-width: 3000px) {
    height: 1250px;
    max-height: 1250px;
  }
  @media (min-width: 3300px) {
    height: 1300px;
    max-height: 1300px;
  }
  @media (min-width: 3500px) {
    height: 1350px;
    max-height: 1350px;
  }
  @media (min-width: 3800px) {
    height: 1400px;
    max-height: 1400px;
  }
  @media (min-width: 4200px) {
    height: 1450px;
    max-height: 1450px;
  }
  .sensor_style {
    transition: @transition05;
    z-index: 2;
    cursor: pointer;
    &:before {
      content: '';
      background: url("../../../images/performance/sensors/sensor_hover.png") no-repeat;
      width: 70%;
      height: 70%;
      display: block;
      position: absolute;
      background-size: contain !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: @transition05;
      pointer-events: none;
      cursor: pointer;
    }
    &.status_bad {
      &:before {
        background: url("../../../images/performance/sensors/sensor_warning.png") no-repeat;
        opacity: 1;
        animation: _show .5s;
      }
      &:after {
        content: '';
        background: url("../../../images/performance/sensors/pulse_animation.svg") no-repeat;
        animation: _show .5s;
        opacity: 1;
        position: absolute;
        top: -14px;
        left: -25px;
        width: 84px;
        height: 50px;
        z-index: 333;
        pointer-events: none;
      }
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
    &.status_bad:hover {
      &:before {
        filter: brightness(115%);
        transition: @transition05;
      }
    }
  }
  &.hidden_tooltips {
    .sensor_style {
      opacity: 0;
      pointer-events: none;
    }
  }
  .renderElement_wrap {
    transition: @transition02;
  }

  .animation {
    position: absolute;
    top: 3px;
    left: -7px;
    pointer-events: none;
  }

  .tess {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .hidden_tooltip {
    opacity: 0;
    pointer-events: none;
  }
  .img_layer {
    transition: opacity .5s ease;
    pointer-events: none;
    &.hidden {
      opacity: 0;
    }
  }
  .usage_map, .img_layer {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    @media @min992 {
      margin: -5% -30% 0 -30%;
      width: calc(100vw + 60%) !important;
      max-width: none;
    }
    @media (min-width: 1200px) {
      margin: 0% -30% 0 -35%;
      width: calc(100vw + 50%) !important;
      max-width: none;
    }
    @media (min-width: 1440px) {
      margin: -1% -25% 0 -25%;
      width: calc(100vw + 40%) !important;
      max-width: none;
    }
    @media (min-width: 1700px) {
      margin: -1% -25% 0 -25%;
      width: calc(100vw + 40%) !important;
      max-width: none;
    }
    @media (min-width: 1700px) {
      margin: -9% -20% 0 -20%;
      width: calc(100vw + 40%) !important;
      max-width: none;
    }
    @media (min-width: 1900px) {
      margin: -9% -12% 0 -12%;
      width: calc(100vw + 24%) !important;
      max-width: none;
    }
    @media (min-width: 2300px) {
      margin: -8% -3% 0 -3%;
      width: calc(100vw + 6%) !important;
      max-width: none;
    }
    @media (min-width: 2700px) {
      margin: -9% 0 0 0;
      width: calc(100vw) !important;
      max-width: none;
    }
    @media (min-width: 3300px) {
      margin: -10% 0 0 0;
      width: calc(100vw) !important;
      max-width: none;
    }
    @media (min-width: 3500px) {
      margin: -11% 0 0 0;
      width: calc(100vw) !important;
      max-width: none;
    }
    @media (min-width: 3800px) {
      margin: -12% 0 0 0;
      width: calc(100vw) !important;
      max-width: none;
    }
    @media (min-width: 4200px) {
      margin: -13% 0 0 0;
      width: calc(100vw) !important;
      max-width: none;
    }
  }
  & * {
    .user-select-none;
  }
}

.red_line, .red_line_2 {
  position: absolute;
  background: @main-color-cl-21;
  z-index: 1;
}
.red_line {
  height: 110px;
  width: 2px;
  left: -5px;
  top: -44%;
}
.red_line_2 {
  width: 85px;
  height: 2px;
  left: -5px;
  top: -44%;
}

.render_lines {
  transition: @transition02;
}

.hidden_lines {
  opacity: 0;
  pointer-events: none;
}

.performance_mouse_hover{
  width: 100%;
  height: 100%;
  position: absolute;
}

.card_wrap {
  width: fit-content;
  height: fit-content;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: @transition02;
  position: absolute;
  z-index: 2;
  transform: translate(-45%, calc(-100% - 20px));
  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.flushing_card_wrap {
  transform: translate(-42%, calc(-100% - 20px));
}

.prototype_sensor {
  z-index: 3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: @transition02;
  transform: translate(-50%, -50%);
  left: -350%;
  top: -200%;
  & > div {
    margin: 0;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.clickable_area{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.elements{
  z-index: 2;
  position: absolute;
  &.cursor {
    cursor: pointer;
  }
}
