.slick-slider {
  height: 100%;
  padding-bottom: 30px;
}
.slick-slider .slick-dots {
  justify-content: center;
  bottom: -8px;
}
.slick-slider .slick-dots > li {
  width: 8px;
  height: 8px;
}
.slick-slider .slick-arrow {
  top: 31.5% !important;
  opacity: 1 !important;
  width: 24px !important;
  height: 24px !important;
  background: inherit !important;
}
.slick-slider .slick-next::before {
  color: inherit;
  opacity: 1;
  background: url("../../../icons/arrow-grey.svg") no-repeat 50% 50%;
}
.slick-slider .slick-prev {
  transform: translate(0, -50%) rotate(-180deg) !important;
}
.slick-slider .slick-prev::before {
  color: inherit;
  opacity: 1;
  background: url("../../../icons/arrow-grey.svg") no-repeat 50% 50%;
}
.slick-slider .slick-arrow.slick-next {
  right: -20px !important;
}
.slick-slider .slick-arrow.slick-prev {
  left: -20px !important;
  padding-left: 8px;
  padding-right: 0 !important;
}
.slick-slider .slick-disabled {
  opacity: 0 !important;
}
