@import "../../variables";

.container {
    width: 190px;
    color: @main-color-cl-01;
    align-items: center;
    margin: 6px 92px 0;
    
    & > span {
        font-size: 18px;
    }

    .header {
        margin-bottom: 29px;
    }

    .status_line {
        width: 100%;
        border: 1px solid @main-color-cl-31;
        margin-bottom: 25px;
    }

    .timeContainer {
        align-items: center;
        margin: 18px 0 40px 0;


        & > span {
            font-size: 18px;
        }

        .alarmIcon {
            margin-right: 8px;
            width: 17px;
            height: 19px;
        }
    }
}

.modal_container {
    width: 230px;
    background-color: white;
    border-radius: 8px;
    padding: 23px;

    .title {
        font-weight: 500;
        margin: 20px 0 27px;
    }

    & > span {
        font-size: 14px;
    }

    .header {
        margin-bottom: 18px;
    }

    .status_line {
        margin-bottom: 16px;
    }

    .timeContainer {
        align-items: center;
        margin: 10px 0 30px 0;

        & > span {
            font-size: 14px;
        }
    }

    .historyIcon {
        width: 24px;
        height: 24px;
    }

    .buttonsContainer {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}
