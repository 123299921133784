@import "../../variables";

.container {
  margin: 0 40px 10px 0;
  background: white;
  box-shadow: @secondary-shadow;
  border-radius: 10px;
  padding: 10px 14px 25px;
  color: @main-color-cl-01;
  width: 230px;
  transition: @transition05;
  border: 2px solid transparent;
  text-align: start;
  &:active {
    box-shadow: @secondary-shadow !important;
  }
  .warning_icon {
    width: 24px;
    height: 24px;
  }
  &.active {
    border: 2px solid @main-color-cl-02;
  }
  &.warning {
    border: 2px solid @main-color-cl-21;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  span {
    display: block;
  }

  .lazy_load_image {
    margin: 8px 0 14px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 198px;
    height: 137px;
    overflow: hidden;
    border-radius: 8px;
    img:not(.warning_icon) {
      background: white;
      pointer-events: none;
      margin: auto 0;
      height: 100%;
      width: auto;
    }
  }
  &.withChoice {
    padding-bottom: 12px;
    cursor: auto;
  }
}
