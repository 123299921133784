@import "../../variables";

.slick-slider {
    height: 100%;
    padding-bottom: 30px;

    .slick-dots {
        justify-content: center;
        bottom: -8px;

        & > li {
            width: 8px;
            height: 8px;
        }
    }

    .slick-arrow {
        top: 31.5% !important;
        opacity: 1 !important;
        width: 24px !important;
        height: 24px !important;
        background: inherit !important;

    }

    .slick-next {
        &::before {
            color: inherit;
            opacity: 1;
            background: url("../../../icons/arrow-grey.svg") no-repeat 50% 50%;
        }
    }

    .slick-prev {
        transform: translate(0, -50%) rotate(-180deg) !important;
        &::before {
            color: inherit;
            opacity: 1;
            background: url("../../../icons/arrow-grey.svg") no-repeat 50% 50%;
        }
    }

    .slick-arrow.slick-next {
        right: -20px !important;
    }

    .slick-arrow.slick-prev {
        left: -20px !important;
        padding-left: 8px;
        padding-right: 0 !important;
    }

    .slick-disabled {
        opacity: 0 !important;
    }
}