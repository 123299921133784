.table_wrap {
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  padding: 16px 24px 24px;
  margin-top: 0 !important;
}
.table * {
  font-family: 'Ubuntu', sans-serif !important;
}
.table thead th {
  font-size: 12px;
  line-height: 26px;
  color: #00205B;
  padding-bottom: 22px;
  border: none;
}
.table tbody tr td {
  height: 40px;
  font-size: 16px;
  line-height: 23px;
  color: #212134;
}
.table tbody tr td,
.table tbody tr:last-child td {
  border-bottom: 1px solid #EAEAEF;
}
.flushingInfoContainer {
  box-shadow: none !important;
  border-radius: none !important;
  width: 600px !important;
  padding: 42px 42px 15px;
}
.flushingInfoContainer thead th {
  padding: 6px 8px 22px;
}
.flushingInfoContainer tbody tr td {
  padding: 0;
}
