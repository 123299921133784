@import "../../variables";
@import "../../animations";

.container {
  width: 160px;
  height: 160px;
  color: @main-color-cl-01;
  position: relative;
  margin: auto;
  margin-bottom: 15px;
  .svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // opacity: 0.5;
    transition: opacity 3s;
  }

  .temperature {
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: @main-color-cl-13;
    .temperature_box {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      span:first-child {
        color: @main-color-cl-01;
        font-size: 32px;
        line-height: 100%;
      }
      span:last-child {
        color: @main-color-cl-15;
        font-size: @font-p;
        margin: 0;
        padding: 0;
      }
    }
    .time_box {
      z-index: 4;
      font-size: 12px;
      margin: 10px 0 -30px;
    }
  }
  & ~ span {
    width: calc(100% + 100px);
  }
}

.subtitle {
  font-size: @font-little-span;
}

.svg_off {
  animation: _hide 3s;
  opacity: 0;
}

.svg_on {
  animation: _show 3s;
  opacity: 1;
}
