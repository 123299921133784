@import "../../variables";

.container {
    position: relative;
    justify-content: space-between;
    align-items: center;
    transition: @transition05;
    
    .animationIcon {
        width: 56px;
        height: 137px;
        margin-bottom: 37px;
    }

    .animationOpacity {
        opacity: 0.5;
        margin-bottom: 39px;
    }

    .successIcon {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 14%;
        left: 25%;
    }

    .successTitle {
        margin-bottom: 0;
        font-size: @font-span;
        font-weight: 420;
    }
}