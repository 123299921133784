@import "../../variables";

.container {
    align-items: center;
    justify-content: center;
    transition: @transition05;

    .temperatureContainer {
        & > p {
            font-size: @font-p;
            font-weight: 500;
            position: relative;
            margin-right: 13px;

            &::after {
                content: "°C";
                position: absolute;
                top: 4px;
                color: @main-color-cl-17;
                font-weight: 400;
                font-size: 12px;
            }
        }
    }

    .flushLoading {
        width : 72px !important;
        height: 72px !important;
        margin-bottom: 27px;
        animation: 3s linear 0s normal none infinite running flushLoading;
        -webkit-animation: 3s linear 0s normal none infinite running flushLoading;
    }

    @keyframes flushLoading {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
    }

    @-webkit-keyframes flushLoading {
        0% {
        transform: rotate(360deg);
        }
        100% {
        transform: rotate(0deg);
        }
    }

    .flushingTitle {
        margin-bottom: 8px;
        font-weight: 420;
        font-size: @font-span;
    }
}