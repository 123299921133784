@import "../../variables";

.flush_info_container {
    & > ul {
        padding: 0;

        & > li {
            padding: 0;
            justify-content: center;
            text-align: center;

            & > div {
                margin: 8px 0;
    
                span {
                    font-size: 14px !important;
                    margin-bottom: 8px;
                    color: @main-color-cl-13
                }

                .MuiTypography-body2 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .MuiTypography-body1 {
                        font-size: 16px !important;
                        color: @main-color-cl-01;
                        font-weight: 600;
                        align-self: center;
                    }
                }
            }
        }
    }

    .MuiDivider-fullWidth {
        margin: 0 4px;
    }

    .symbol {
        color: @main-color-cl-17 !important;
        margin-left: 3px;
        font-size: 14px !important;
        margin-bottom: 0 !important;
        padding-bottom: 2px;
    }

    .degreesSymbol {
        padding-bottom: 4px;
    }
}