.primaryButton {
  background-color: #00205B !important;
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 16px !important;
  padding: 0 !important;
  border-radius: 8px !important;
}
.primaryButton:hover {
  background-color: #002B7A !important;
}
.greyButton {
  background-color: white !important;
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 16px !important;
  color: #666687 !important;
  font-weight: 600 !important;
  padding: 0 !important;
  border: 1px solid #666687 !important;
  border-radius: 8px !important;
}
.greyButton:hover {
  background-color: white !important;
}
.iconButton {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: 1px solid #666687 !important;
  border-radius: 8px !important;
  min-width: 30px !important;
}
.iconButton:hover {
  background-color: white !important;
}
