@import "../../variables";

.container {
    margin-top: 36px;
    margin-bottom: 8px;

    .flushingInfoContainer {
        border-left: 1px solid @main-color-cl-18;

        .tableHeader {
            margin-top: 6px;
            justify-content: space-between;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            h2 {
                margin: 0 50px;
                font-size: 24px;
            }
        }
    }
}