.modal_sensor {
  z-index: 2;
  position: absolute;
  top: 25%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
}
.modal_sensor > div {
  margin: 0;
}
.modal_sensor.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.container {
  margin: 0 -1px 10px 0;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px 35px 20px;
  text-align: center;
  color: #00205B;
  width: 230px;
  height: 366px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container.isModal {
  margin: 0;
}
.container .name_wrap {
  min-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.container .name_wrap .name {
  font-weight: 500;
  margin: 0;
}
.container .temperatureCircleContainer {
  margin-bottom: 40px;
}
.draggable_place {
  width: 100%;
  position: absolute;
  height: 90px;
  top: 0;
}
